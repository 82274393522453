import { useLayoutEffect } from 'react'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import { useRecoilState, useRecoilValue } from 'recoil'
import styled from '@mui/material/styles/styled'
import Stack from '@mui/material/Stack'
import MenuList from '@mui/material/MenuList'
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'
import Box from '@mui/material/Box'
import ListItemIcon from '@mui/material/ListItemIcon'
import CaseIcon from '@mui/icons-material/Assignment'
import CategoryIcon from '@mui/icons-material/Category'
import HomeWorkIcon from '@mui/icons-material/HomeWork'
import ItemIcon from '@mui/icons-material/FolderCopy'
import SettingsIcon from '@mui/icons-material/Settings'
import MemberIcon from '@mui/icons-material/Badge'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import RoleIcon from '@mui/icons-material/Groups'
import CustomerIcon from '@mui/icons-material/Person'
import MuiLink from '@mui/material/Link'
import Divider from '@mui/material/Divider'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import AlertIcon from '@mui/icons-material/Report'

import useRoute from '../hooks/useNavigate'
import { portalSettingState } from 'state/portalSettingStates'
import { BackButtonGrey } from 'components/StyledComponents'
import { isMenuOpenState } from 'state/layoutStates'
import {
  PATH_ACCESSES,
  PREPORTI_SUPPORT_LOCALES_MAP,
  Path,
  SMALL_SCREEN_SIZE,
} from '../commonConstants'
import useMember from 'hooks/useMember'
import { userAccessState } from 'state/userStates'
import { ForwardingContactIcon, TeamIcon } from 'components/icons/Icons'

export const MENU_FULL_WIDTH = 260
export const MENU_FOLDED_WIDTH = 64

export const MenuWrapper = styled(Stack)`
  box-shadow: 0px 10px 30px 0px rgba(17, 38, 146, 0.05);
  background: ${({ theme }) => theme.palette.background.paper};
  border-top-right-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  border-bottom-right-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  height: 100%;
  padding: 8px;

  & .MuiList-root {
    height: 100%;

    & .MuiMenuItem-root {
      padding: 14px;
      border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;

      & .MuiListItemText-primary {
        font-size: 14px;
        line-height: 14px;
        font-weight: 500;
        white-space: normal;
      }

      & svg {
        color: ${({ theme }) => theme.palette.primary.main};
      }
    }

    & .Mui-selected,
    & .Mui-selected:hover {
      background-color: ${({ theme }) => theme.palette.primary.main};
      color: ${({ theme }) => theme.palette.primary.contrastText};

      & svg {
        color: ${({ theme }) => theme.palette.primary.contrastText};
      }
    }
  }
`

const Link = styled(MuiLink)`
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 12px;
`

const InfoIcon = styled(InfoOutlinedIcon)`
  color: ${({ theme }) => theme.palette.primary.main};
  cursor: pointer;
`

const ArrowLeft = ArrowBackIcon
const ArrowRight = styled(ArrowBackIcon)`
  transform: rotate(180deg);
`

const MainMenu: React.FC = () => {
  const { goTo } = useRoute()
  const location = useLocation()
  const { formatMessage, locale } = useIntl()
  const portalSetting = useRecoilValue(portalSettingState)
  const [isOpen, setIsOpen] = useRecoilState(isMenuOpenState)
  const { checkAccesses } = useMember()
  const userAccess = useRecoilValue(userAccessState)

  const toggleMenu = (): void => {
    setIsOpen((prev) => !prev)
  }

  useLayoutEffect(() => {
    const vw = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0,
    )

    if (vw < SMALL_SCREEN_SIZE) {
      setIsOpen(false)
    }
  }, [])

  return (
    <MenuWrapper
      width={isOpen ? `${MENU_FULL_WIDTH}px` : `${MENU_FOLDED_WIDTH}px`}
    >
      <Stack height={'100%'}>
        <Box paddingX={1}>
          <BackButtonGrey
            onClick={toggleMenu}
            size="small"
            aria-label={formatMessage({
              id: 'general.icon_button.main_menu_toggle',
            })}
          >
            {isOpen ? <ArrowLeft /> : <ArrowRight />}
          </BackButtonGrey>
        </Box>

        {portalSetting && (
          <>
            <Box flexGrow={1}>
              <MenuList>
                {checkAccesses(PATH_ACCESSES[Path.CASES_LIST]) && (
                  <MenuItem
                    selected={
                      location.pathname.includes('cases') ||
                      location.pathname === '/'
                    }
                    onClick={(): void => {
                      goTo(Path.CASES_LIST)
                    }}
                    tabIndex={0}
                  >
                    <ListItemIcon>
                      <CaseIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText sx={{ opacity: isOpen ? 1 : 0 }}>
                      {formatMessage({ id: 'main_menu.cases' })}
                    </ListItemText>
                  </MenuItem>
                )}

                {checkAccesses(PATH_ACCESSES[Path.MEMBERS_LIST]) && (
                  <MenuItem
                    selected={location.pathname.includes(Path.MEMBERS_LIST)}
                    onClick={(): void => {
                      goTo(Path.MEMBERS_LIST)
                    }}
                    tabIndex={0}
                  >
                    <ListItemIcon>
                      <MemberIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText sx={{ opacity: isOpen ? 1 : 0 }}>
                      {formatMessage({ id: 'member_list.header' })}
                    </ListItemText>
                  </MenuItem>
                )}

                {checkAccesses(PATH_ACCESSES[Path.TEAMS_LIST]) && (
                  <MenuItem
                    selected={location.pathname.includes(Path.TEAMS_LIST)}
                    onClick={(): void => {
                      goTo(Path.TEAMS_LIST)
                    }}
                    tabIndex={0}
                  >
                    <ListItemIcon>
                      <TeamIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText sx={{ opacity: isOpen ? 1 : 0 }}>
                      {formatMessage({ id: 'team_list.header' })}
                    </ListItemText>
                  </MenuItem>
                )}

                {checkAccesses(PATH_ACCESSES[Path.CUSTOMERS_LIST]) && (
                  <MenuItem
                    selected={location.pathname.includes(Path.CUSTOMERS_LIST)}
                    onClick={(): void => {
                      goTo(Path.CUSTOMERS_LIST)
                    }}
                    tabIndex={0}
                  >
                    <ListItemIcon>
                      <CustomerIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText sx={{ opacity: isOpen ? 1 : 0 }}>
                      {formatMessage({ id: 'customer_list.header' })}
                    </ListItemText>
                  </MenuItem>
                )}

                {checkAccesses(PATH_ACCESSES[Path.CONTACTS_LIST]) && (
                  <MenuItem
                    selected={location.pathname.includes(Path.CONTACTS_LIST)}
                    onClick={(): void => {
                      goTo(Path.CONTACTS_LIST)
                    }}
                    tabIndex={0}
                  >
                    <ListItemIcon>
                      <ForwardingContactIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText sx={{ opacity: isOpen ? 1 : 0 }}>
                      {formatMessage({ id: 'contact_list.header' })}
                    </ListItemText>
                  </MenuItem>
                )}

                {checkAccesses(PATH_ACCESSES[Path.ALERTS_LIST]) && (
                  <MenuItem
                    selected={location.pathname.includes(Path.ALERTS_LIST)}
                    onClick={(): void => {
                      goTo(Path.ALERTS_LIST)
                    }}
                    tabIndex={0}
                  >
                    <ListItemIcon>
                      <AlertIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText sx={{ opacity: isOpen ? 1 : 0 }}>
                      {formatMessage({ id: 'alert_list.header' })}
                    </ListItemText>
                  </MenuItem>
                )}

                {checkAccesses(PATH_ACCESSES[Path.ITEMS_LIST]) && (
                  <MenuItem
                    selected={location.pathname.includes(Path.ITEMS_LIST)}
                    onClick={(): void => {
                      goTo(Path.ITEMS_LIST)
                    }}
                    tabIndex={0}
                  >
                    <ListItemIcon>
                      <ItemIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText sx={{ opacity: isOpen ? 1 : 0 }}>
                      {formatMessage({ id: 'portal_item_list.header' })}
                    </ListItemText>
                  </MenuItem>
                )}

                {checkAccesses(PATH_ACCESSES[Path.CATEGORIES]) && (
                  <MenuItem
                    selected={location.pathname.includes(Path.CATEGORIES)}
                    onClick={(): void => {
                      goTo(Path.CATEGORIES)
                    }}
                    tabIndex={0}
                  >
                    <ListItemIcon>
                      <CategoryIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText sx={{ opacity: isOpen ? 1 : 0 }}>
                      {formatMessage({ id: 'edit_categories.header' })}
                    </ListItemText>
                  </MenuItem>
                )}

                {checkAccesses(PATH_ACCESSES[Path.ROLES_LIST]) && (
                  <MenuItem
                    selected={location.pathname.includes(Path.ROLES_LIST)}
                    onClick={(): void => {
                      goTo(`${Path.ROLES_LIST}/0`)
                    }}
                    tabIndex={0}
                  >
                    <ListItemIcon>
                      <RoleIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText sx={{ opacity: isOpen ? 1 : 0 }}>
                      {formatMessage({ id: 'member_role_list.header' })}
                    </ListItemText>
                  </MenuItem>
                )}
              </MenuList>
            </Box>

            <Stack>
              <MenuList>
                {checkAccesses(PATH_ACCESSES[Path.PORTALS_EDIT]) && (
                  <MenuItem
                    selected={location.pathname.includes(Path.PORTALS_EDIT)}
                    onClick={(): void => {
                      goTo(`${Path.PORTALS_EDIT}/${portalSetting.id}`)
                    }}
                    tabIndex={0}
                  >
                    <ListItemIcon>
                      <SettingsIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText sx={{ opacity: isOpen ? 1 : 0 }}>
                      {formatMessage({ id: 'portal_setting.header' })}
                    </ListItemText>
                  </MenuItem>
                )}
              </MenuList>

              <Stack padding="14px" spacing={2} height="130px">
                <Divider />
                <Stack direction="row" spacing={2}>
                  <InfoIcon fontSize="small" onClick={toggleMenu} />

                  <Stack spacing={1} sx={{ opacity: isOpen ? 1 : 0 }}>
                    <Link
                      href={`https://www.preporti.com/${
                        PREPORTI_SUPPORT_LOCALES_MAP[locale] ?? ''
                      }faq`}
                      target="_blank"
                    >
                      {formatMessage({ id: 'login_footer.link.faq' })}
                    </Link>
                    <Link
                      href={`https://www.preporti.com/${
                        PREPORTI_SUPPORT_LOCALES_MAP[locale] ?? ''
                      }terms-of-use`}
                      target="_blank"
                    >
                      {formatMessage({ id: 'login_footer.link.terms' })}
                    </Link>
                    <Link
                      href={`https://www.preporti.com/${
                        PREPORTI_SUPPORT_LOCALES_MAP[locale] ?? ''
                      }privacy-policy`}
                      target="_blank"
                    >
                      {formatMessage({
                        id: 'login_footer.link.privacy_policy',
                      })}
                    </Link>
                    <Link
                      href={`https://www.preporti.com/${
                        PREPORTI_SUPPORT_LOCALES_MAP[locale] ?? ''
                      }`}
                      target="_blank"
                    >
                      {formatMessage({ id: 'login_footer.link.about_us' })}
                    </Link>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </>
        )}

        <Box>
          <MenuList>
            {userAccess?.userData.admin && (
              <MenuItem
                selected={location.pathname === Path.PORTALS_LIST}
                onClick={(): void => {
                  goTo(Path.PORTALS_LIST)
                }}
                tabIndex={0}
              >
                <ListItemIcon>
                  <HomeWorkIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText sx={{ opacity: isOpen ? 1 : 0 }}>
                  {formatMessage({ id: 'portal_list.header' })}
                </ListItemText>
              </MenuItem>
            )}
          </MenuList>
        </Box>
      </Stack>
    </MenuWrapper>
  )
}

export default MainMenu
